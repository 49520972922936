<template>
	<div>
		<Modal :options="{width: '90vw', close: true}" @close="cerrar_modal">
			<div class="title">Mensajes</div>
				<div class="body">
					<DataGrid :configuracion="datagrid_config" :data="mensajes" @seleccionadas="seleccionado" @ready="buscar_botones">
						<div class="row">
							<div class="col-sm-2"><button class="btn btn-block btn-secondary" @click="obtener_mensajes">Recargar</button></div>
						</div>
					</DataGrid>
					<Modal v-if="mensaje" :options="{width: (mensaje.garantia ? '95vw' : '50vw'), close: true}" @close="cerrar_mensaje">
						<div class="title">Mensaje</div>
						<div class="body">
							<div class="row">
								<div :class="(mensaje.garantia ? 'col-sm-6' : 'col-sm-12')">
									<fieldset>
										<legend>Mensaje</legend>
										<div class="row form-group">
											<label for="mensaje.nombre" class="col-form-label col-sm-2">Nombre</label>
											<div class="col-sm-10"><input class="form-control" :value="mensaje.nombre" readonly></div>
										</div>
										<div class="row form-group">
											<label for="mensaje.email" class="col-form-label col-sm-2">Email</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.email" readonly></div>
											<label for="mensaje.telefono" class="col-form-label col-sm-2">Teléfono</label>
											<div class="col-sm-4"><input  class="form-control" :value="mensaje.telefono" readonly></div>
										</div>
										<div class="row form-group">
											<label for="mensaje.mensaje" class="col-form-label col-sm-2">Mensaje</label>
											<div class="col-sm-10"><div class="form-control mensaje" readonly>{{mensaje.mensaje}}</div></div>
										</div>
									</fieldset>
								</div>
								<div v-if="mensaje.garantia" class="col-sm-6">
									<fieldset>
										<legend>Garantia</legend>
										<div class="row form-group">
											<label for="garantia.fideicomiso" class="col-form-label col-sm-2">Fideicomiso</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.garantia.fideicomiso" readonly></div>
											<label for="garntia.precio" class="col-form-label col-sm-2">Precio</label>
											<div class="col-sm-4"><input class="form-control" :value="'$'+ $helper.moneyFormat(mensaje.garantia.precio)" readonly></div>
										</div>
										<div class="row form-group">
											<label for="garantia.titulo_nombre" class="col-form-label col-sm-2">Nombre</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.garantia.titulo_nombre" readonly></div>
											<label for="garantia.anos" class="col-form-label col-sm-2">Edad</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.garantia.edad" readonly></div>
										</div>
										<div class="row form-group">
											<label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
											<div class="col-sm-10"><input class="form-control" :value="mensaje.garantia.descripcion" readonly></div>
										</div>
										<div class="row form-group">
											<label for="garantia.recamaras" class="col-form-label col-sm-3">Recamaras</label>
											<div class="col-sm-3"><input class="form-control" :value="mensaje.garantia.recamaras" readonly></div>
											<label for="garantia.banos" class="col-form-label col-sm-3">Baños</label>
											<div class="col-sm-3"><input class="form-control" :value="mensaje.garantia.banos" readonly></div>
										</div>
										<div class="row form-group">
											<label for="garantia.medios_banos" class="col-form-label col-sm-3">Medios baños</label>
											<div class="col-sm-3"><input class="form-control" :value="mensaje.garantia.medios_banos" readonly></div>
											<label for="garantia.estacionamientos" class="col-form-label col-sm-3">Estacionamientos</label>
											<div class="col-sm-3"><input class="form-control" :value="mensaje.garantia.estacionamientos" readonly></div>
										</div>
										<div class="row form-group">
											<label class="col-form-label col-sm-2">Amenidades</label>
											<div class="col-sm-4"><span v-for="amenidad in nombres_amenidades" :key="amenidad.id" class="badge badge-secondary text-white">{{ amenidad.amenidad }}</span></div>
										</div>
										<div class="row form-group">
											<label for="garantia.superficie" class="col-form-label col-sm-2">Superficie</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.garantia.superficie+'m2'" readonly></div>
											<label for="garntia.superficie_construida" class="col-form-label col-sm-2">Superficie construida</label>
											<div class="col-sm-4"><input class="form-control" :value="mensaje.garantia.superficie_construida+'m2'" readonly></div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
						<div class="footer">
							<div class="row">
								<div class="col-sm-2 offset-10"><button class="btn btn-primary btn-block" @click="cerrar_mensaje">Regresar</button></div>
							</div>
						</div>						
					</Modal>
				</div>
		</Modal>
	</div> 
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV3'
	import Modal from '@/components/Modal'

	import api from '@/apps/garantias/api/otros'

	export default {
		components: {
			DataGrid, Modal
		}
		,props:{
			amenidades:{
				type: Array
				,required: true
			}
		}
		,data: function() {
			return {
				datagrid_config: {
					name: 'garantias_mensajes'
					,selector: false
					,cols: {
						id: 'Ver mensaje'
						,garantia: 'Fideicomiso'
						,nombre: 'Nombre'
						,email: 'Email'
						,telefono: 'Teléfono'
						,estatus: 'Estatus'
						,created_at: 'Fecha envio'
					}
					,mutators: {
						garantia: function(val) {
							return val ? val.fideicomiso : '-';
						}
						,estatus: function(val) {
							return val == 1 ? '<span class="badge badge-pill badge-danger">Sin leer</span>' : '<span class="badge badge-pill badge-primary">Leído</span>';
						}
						,created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD')
						}
						,id: function(val){
							return `<button class="btn btn-small btn-info" id="${val}" name="ver_mensaje" data-id="${val}">Ver mensaje</button>`;
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionados: null
				,mensajes: []
				,mensaje: null
				,cerrar_modal_mensaje: false
			}
		}
		,mounted: function() {
			this.obtener_mensajes();
		}
		,methods: {
			obtener_mensajes: async function() {
				try {
					this.mensajes  = (await api.obtener_mensajes_all()).data;
					this.$log.info('todos los mensajes',this.mensajes);

				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,seleccionado(seleccionado){
				this.seleccionados = seleccionado;
			}
			,seleccionar_mensaje: async function(e) {
				try {
					this.mensaje = (await api.obtener_mensaje(e.target.dataset.id)).data;
					this.ver_mensaje = true;

					if (this.mensaje.estatus)
						await api.leer_mensaje(this.mensaje.id);

				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cerrar_mensaje: function() {
				this.mensaje = null;
				this.seleccionar = false;
				this.obtener_mensajes();
			},
			cerrar_modal(){
				this.cerrar_modal_mensaje = false;
				this.$emit('close');
			},
			formatear_amenidades(){

				let amenidades_format = [];
				this.amenidades.forEach(amenidad=>{
					if (this.mensaje.garantia.amenidades.some(new_amenidad => new_amenidad.amenidad === amenidad.valor))
						amenidades_format.push({id:amenidad.id, amenidad: amenidad.nombre});
				});            
				return this.mensaje.garantia.amenidades = amenidades_format;  				
			},
			buscar_botones(){
				let botones = document.querySelectorAll('button[name="ver_mensaje"]');
				// this.$log.info('botones', botones)

				botones.forEach(btn => {
					btn.removeEventListener('click',this.seleccionar_mensaje);
					btn.addEventListener('click', this.seleccionar_mensaje);
				});
			},
		},
		computed:{
			nombres_amenidades(){
			return this.formatear_amenidades();
			}
		}
	}
</script>

<style lang="scss">
	.mensaje {
		height: 200px !important;
	}
</style>